<template>
  <div class="my-6">
    <div class="max-w-xl h-2.5 relative mx-auto">
      <div class="w-full h-full bg-primary bg-opacity-10 rounded-full">
        <div
          v-for="n in totalPages"
          :key="n"
        >
          <span
            class="w-1 h-1 block rounded-full bg-primary absolute top-1/3"
            :style="{ left:  dotPosition(n) + '%' }"
            ></span>

          <div
            class="absolute top-2/4 transform -translate-y-2/4 rounded-full bg-orange text-white ring ring-white w-6 h-6 text-center left-1/4 -ml-3 z-10 opacity-0"
            :class="[{ 'opacity-100': nrVisible(n)}]"
            :style="{ left:  dotPosition(n) + '%' }"
          >
            <span>{{ n }}</span>
          </div>
        </div>
        <div>
          <span
            class="block rounded-full bg-orange w-1/4 h-full absolute"
            :style="{ width: progressWidth + '%' }"
          ></span>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  name: 'TheProgressbar',
  props: {
    currPage: Number,
    totalPages: Number
  },
  methods: {
    dotPosition (n) {
      return n * 100 / this.totalPages
    },

    nrVisible (n) {
      if (n <= this.currPage) return true
      return false
    }
  },
  computed: {
    progressWidth () {
      return this.currPage * 100 / this.totalPages
    },
  }
}
</script>
