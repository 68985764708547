<template>
  <div v-show="show" class="alert fixed z-10 bottom-4 left-2/4 transform -translate-x-1/2 flex flex-row items-center bg-red-200 p-5 rounded border-b-2 border-red-300">
    <div class="alert-content ">
      <div class="alert-title font-semibold text-lg text-red-800">
        {{ $t('acssa.error')}}
      </div>
      <div class="alert-description text-sm text-red-600">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Alert',
  props: {
    message: String,
    show: Boolean
  }
}
</script>
