<template>
  <div
    class="w-full xl:container mx-auto"
  >
    <div 
       class="flex flex-col md:flex-row min-h-150"
       :class="{ 'border-orange': isMarked() }"
    >
      <div
        v-if="question.nr !== 999"
        class="md:w-16 w-full bg-primary text-white flex justify-center items-center"
        :class="{ 'bg-orange': isMarked(), 'opacity-0': !question.show_id }"
      >
        <span class="text-xl">{{ question.nr }}</span>
      </div>
      <div
        class="w-full flex flex-col border border-primary md:text-center bg-orange bg-opacity-0"
        :class="{ 'bg-opacity-10': isMarked() }"
      >
        <div class="py-3.5 text-base pl-2.5 md:pl-0 md:text-xl">
          <p :class="{ 'text-orange': isMarked() }">{{ question.question }}</p>
        </div>
        <span class="w-full md:w-19/20 h-px bg-primary mx-auto"></span>

        <form @submit.prevent class="h-full">
          <div class="hide-span flex flex-col md:flex-row justify-between md:items-center h-full">
            <template v-for="answer in question.answers" :key="answer.id">
              <div class="pt-2.5 pb-4 px-2.5 md:px-4" :class="[ calcWidth(question.answers.length) ]">
                <p class="text-base hidden md:block">{{ answer.desc }}</p>

                <label class="flex md:justify-center items-center">
                  <div class="bg-white border border-primary w-6 h-6 flex flex-shrink-0 justify-center items-center mr-3 focus-within:border-blue-500">

                    <input
                      type="radio" 
                      :value="answer.points" 
                      v-model="selectedAnswer"
                      @change="updateScore"
                      class="opacity-0 absolute"
                    >
                    <svg
                      class="fill-current border-orange bg-orange hidden w-6 h-6 text-white pointer-events-none"
                      viewBox="-10 0 40 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"
                    /></svg>
                  </div>
                  <div class="text-left">{{ answer.name }} </div>
                  <span v-if="answer.desc" class="md:hidden">&nbsp;( {{ answer.desc }} )</span>
                </label>

              </div>
              <span class="w-19/20 h-px bg-primary block mx-auto md:hidden"></span>
              <span class="w-px h-5/6 bg-primary my-auto hidden md:block"></span>
            </template>
          </div>
        </form>

      </div>
    </div>
	</div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Question',
  props: {
    question: Object,
    page: Number,
    error: String,
    scores: Object
  },
  setup(props, { emit }) {
    const selectedAnswer = ref('')

    selectedAnswer.value = props.scores[props.page][props.question.id] !== null
      ? props.scores[props.page][props.question.id]
      : ''
    
    const updateScore = () => {
      emit('update', {
        id: props.question.id,
        points: selectedAnswer.value,
        page: props.page
      })
    }

    const isMarked = () => {
      return selectedAnswer.value !== ''
    }

    const calcWidth = (n) => {
      const w =  12 / n
      return 'md:w-' + w + '/12'
    }

    return {
      selectedAnswer,
      updateScore,
      isMarked,
      calcWidth
    }
  }
}
</script>

<style>
input:checked + svg {
  display: block;
}
.hide-span > span:last-child,
.hide-span > span:nth-last-child(2) {
  display: none;
}
.control {
  position: relative;
  padding-left: 2rem;
  cursor: pointer;
}
.control input {
  position: absolute;
  opacity: 0;
  z-index: -1; /* Put the input behind the label so it doesn't overlay text */
}
.control-indicator {
  position: absolute;
  left: 0;
  display: block;
  width:  25px;
  height: 25px;
  line-height: 1rem;
  font-size: 65%;
  color: #eee;
  text-align: center;
  background-color: #fff;
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* Hover state */
/* Uncomment if you need it, but be aware of the sticky iOS states.
.control:hover .control-indicator {
  color: #fff;
  background-color: #ccc;
}
*/

/* Focus */
.control input:focus ~ .control-indicator {
  box-shadow: 0 0 0 .075rem #fff, 0 0 0 .2rem #F75924;
}

/* Checked state */
.control input:checked ~ .control-indicator {
  color: #fff;
  background-color: #F75924;
}

/* Active */
.control input:active ~ .control-indicator {
  color: #fff;
  background-color: #84c6ff;
}

/* checkbox modifiers */
.checkbox .control-indicator {
  border: 1px solid #939285;
}

</style>
