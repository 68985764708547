<template>
  <div class="py-5 md:pt-20">
		<div class="block md:hidden">
			<router-link :to="`/${locale}`">
				<img class="mx-auto" :src="require('@/assets/home-icon.svg')" />
			</router-link>
			<span class="w-full h-px bg-orange block my-5"></span>
		</div>

		<div class="flex space-x-12 justify-center">
			<div @click="$emit('prev')" class="flex items-center cursor-pointer">
				<div class="pl-4"><img :src="require('@/assets/arrow-icon.svg')" /></div>
			</div>
			<div>
				<router-link :to="`/${locale}`">
					<img class="mx-auto hidden md:block" :src="require('@/assets/home-icon.svg')" />
				</router-link>
			</div>
			<div @click="$emit('next')" class="flex items-center cursor-pointer">
				<div class="pl-4 transform rotate-180">
					<img :src="require('@/assets/arrow-icon.svg')" />
				</div>
			</div>
		</div>
		<span class="w-full h-px bg-orange block my-5"></span>

		<TheProgressbar :currPage="currPage" :totalPages="totalPages" />

	</div>
</template>

<script>
import TheProgressbar from '@/components/TheProgressbar'
import { useI18n } from 'vue-i18n'

export default {
	name: 'TheFooter',
	components: {
		TheProgressbar
	},
	props: {
		currPage: Number,
		totalPages: Number
	},
  setup () {
    const { locale } = useI18n()

    return {
      locale: locale.value
    }
  }
}
</script>
