export const acssa_coords = {
  firstPage: {
    date: {
      date: [513, 791],
      hour: [462, 791], 
    },
    1: {
      point_0: [269, 734],
      point_1: [346, 734],
      point_2: [422, 734],
      point_3: [498, 734]
    },
    2: {
      point_0: [269, 700],
      point_1: [346, 700],
      point_2: [422, 700],
      point_3: [498, 700]
    },
    3: {
      point_0: [269, 662],
      point_1: [346, 662],
      point_2: [422, 662],
      point_3: [498, 662]
    },
    4: {
      point_0: [269, 623],
      point_1: [346, 623],
      point_2: [422, 623],
      point_3: [498, 623]
    },
    5: {
      point_0: [269, 586],
      point_1: [346, 586],
      point_2: [422, 586],
      point_3: [498, 586]
    },
    6: {
      point_0: [269, 548],
      point_1: [346, 548],
      point_2: [422, 548],
      point_3: [498, 548]
    },
    7: {
      point_0: [269, 470],
      point_1: [346, 470],
      point_2: [422, 470],
      point_3: [498, 470]
    },
    8: {
      point_0: [269, 433],
      point_1: [346, 433],
      point_2: [422, 433],
      point_3: [498, 433]
    },
    9: {
      point_0: [269, 395],
      point_1: [346, 395],
      point_2: [422, 395],
      point_3: [498, 395]
    },
    10: {
      point_0: [269, 356],
      point_1: [346, 356],
      point_2: [422, 356],
      point_3: [498, 356]
    },
    11: {
      point_0: [55, 273],
      point_1: [180, 273],
      point_2: [312, 273],
      point_3: [442, 273]
    },
    12: {
      point_0: [55, 235],
      point_1: [180, 235],
      point_2: [312, 235],
      point_3: [442, 235]
    },
    13: {
      point_0: [55, 198],
      point_1: [180, 198],
      point_2: [312, 198],
      point_3: [442, 198]
    },
    14: {
      point_no: [422, 134],
      point_yes: [499, 134],
    },
    15: {
      point_no: [422, 114],
      point_yes: [499, 114]
    },
    16: {
      point_no: [422, 93],
      point_yes: [499, 93],
    },
    17: {
      point_no: [422, 72],
      point_yes: [499, 72],
    },
    18: {
      point_no: [422, 50],
      point_yes: [499, 50],
    },
    scores: {
      id_1: [520, 519],
      id_2: [520, 327],
      id_3: [520, 174]
    }
  }
}

export const acssb_coords = {
  firstPage: {
    treatment_date: [172, 787],
    date: {
      date: [513, 791],
      hour: [462, 791], 
    },
    999: {
      point_0: [40, 725],
      point_1: [146, 725],
      point_2: [255, 725],
      point_3: [360, 725],
      point_4: [468, 725],
    },
    1: {
      point_0: [269, 670],
      point_1: [346, 670],
      point_2: [422, 670],
      point_3: [498, 670]
    },
    2: {
      point_0: [269, 638],
      point_1: [346, 638],
      point_2: [422, 638],
      point_3: [498, 638]
    },
    3: {
      point_0: [269, 602],
      point_1: [346, 602],
      point_2: [422, 602],
      point_3: [498, 602]
    },
    4: {
      point_0: [269, 565],
      point_1: [346, 565],
      point_2: [422, 565],
      point_3: [498, 565]
    },
    5: {
      point_0: [269, 529],
      point_1: [346, 529],
      point_2: [422, 529],
      point_3: [498, 529]
    },
    6: {
      point_0: [269, 494],
      point_1: [346, 494],
      point_2: [422, 494],
      point_3: [498, 494]
    },
    7: {
      point_0: [269, 423],
      point_1: [346, 423],
      point_2: [422, 423],
      point_3: [498, 423]
    },
    8: {
      point_0: [269, 388],
      point_1: [346, 388],
      point_2: [422, 388],
      point_3: [498, 388]
    },
    9: {
      point_0: [269, 352],
      point_1: [346, 352],
      point_2: [422, 352],
      point_3: [498, 352]
    },
    10: {
      point_0: [269, 315],
      point_1: [346, 315],
      point_2: [422, 315],
      point_3: [498, 315]
    },
    11: {
      point_0: [55, 243],
      point_1: [180, 243],
      point_2: [312, 243],
      point_3: [442, 243]
    },
    12: {
      point_0: [55, 206],
      point_1: [180, 206],
      point_2: [312, 206],
      point_3: [442, 206]
    },
    13: {
      point_0: [55, 168],
      point_1: [180, 168],
      point_2: [312, 168],
      point_3: [442, 168]
    },
    14: {
      point_no: [422, 110],
      point_yes: [499, 110],
    },
    15: {
      point_no: [422, 91],
      point_yes: [499, 91]
    },
    16: {
      point_no: [422, 73],
      point_yes: [499, 73],
    },
    17: {
      point_no: [422, 54],
      point_yes: [499, 54],
    },
    18: {
      point_no: [422, 34],
      point_yes: [499, 34],
    },
    scores: {
      id_1: [520, 469],
      id_2: [520, 289],
      id_3: [520, 146]
    }
  }
}

export const lutire_coords = {
  firstPage: {
    1: {
      point_0: [37, 704],
      point_20: [71, 704],
      point_35: [103, 704],
    },
    2: {
      point_0: [37, 641],
      point_30: [127, 641],
      point_50: [216, 641],
    },
    3: {
      point_0: [37, 578],
      point_40: [127, 578],
    },
    4: {
      point_0: [37, 515],
      point_10: [127, 515],
    },
    5: {
      point_0: [37, 452],
      point_40: [70, 452],
    },
    6: {
      point_0: [37, 388],
      point_50: [70, 388],
    },
    scores: {
      id_1: [520, 469],
    }
  }
}
