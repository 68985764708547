import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const useData = (form) => {
  const route = useRoute()
  const { locale } = useI18n()
  const data = ref({})
  const pageData = ref([])
  const isLoaded = ref(true)
  
  const init = () => {
    isLoaded.value = false
    if (form === 'part_a') getData('part_a')
    if (form === 'part_b') getData('part_b')
    if (form === 'lutire') getData('lutire')
  }

  const getData = async (api) => {
    const module = await import(
      /* webpackChunkName: "locale-[request]" */ `@/api/data_${api}_${route.params.locale}`
    )
    data.value = JSON.parse(module.default)
    isLoaded.value = true
	}

  const getPage = (page) => {
    if (data.value.pages) {
      pageData.value = data.value.pages.find(i => i.page_nr === page)
    }
  }

  init()

  watch(locale, () => {
    init()
  })

  return {
    data: computed(() => data.value),
    pageData: computed(() => pageData.value),
    totalPages: computed(() => data.value.total_pages),
    isLoaded: computed(() => isLoaded.value),
    getPage
  }
}

export default useData
