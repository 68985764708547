import { ref, computed } from 'vue'
import dayjs from 'dayjs'

const date_f = ref('')
const time_f = ref('')
const now = ref(null)

const useTimestamp = () => {

  const format_date = (date) => {
    date_f.value = date.format('DD.MM.YYYY')
  }

  const format_time = (date) => {
    time_f.value = date.format('HH:mm')
  }

  const getTime = () => {
    now.value = dayjs()
    format_date(now.value)
    format_time(now.value)
  }

  getTime()

  return {
    date_f: computed(() => date_f.value),
    time_f: computed(() => time_f.value),
    now: computed(() => now.value)
  }
}

export default useTimestamp
