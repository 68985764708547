import { ref, computed } from 'vue'
import useData from '@/composables/useData'

const scores = ref({})
const errors = ref({})

const useScore = (form) => {

  const { data } = useData(form)

  const init = () => {
    data.value.pages.map(p => {
      if (p.id) scores.value[p.id] = {}
      if (p.questions && p.questions.length > 0) {
        p.questions.map(q => scores.value[p.id][q.id] = null )
      }
    })
  }

  const validate = (page, score) => {
    errors.value = {}

    for (let i in score[page]) {
      if (score[page][i] === null) errors.value[i] = "Is not set"
    }
  }

  const addToScore = (t_value) => {
    scores.value[t_value.page][t_value.id] = t_value.points
  }

  const calcScore = (score) => {
    let sum = 0
    for (let i in score) {
      if (typeof score[i] === "number") {
        sum += score[i]
      }
    }

    return sum
  }
  
  return {
    scores: computed(() => scores.value),
    addToScore,
    calcScore,
    init,
    validate,
    errors
  }
}

export default useScore

